export const routes = [
    {
        path: '/instructors',
        name: 'instructors.browse',
        component: () => import(/* webpackChunkName: "InstructorsBrowse" */ '@/views/app/InstructorsBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/instructors/create',
        name: 'instructors.create',
        component: () => import(/* webpackChunkName: "InstructorsActions" */ '@/views/app/InstructorsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/instructors/:id/edit',
        name: 'instructors.edit',
        component: () => import(/* webpackChunkName: "InstructorsActions" */ '@/views/app/InstructorsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/instructors/:id/delete',
        name: 'instructors.delete',
        component: () => import(/* webpackChunkName: "InstructorsActions" */ '@/views/app/InstructorsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
]